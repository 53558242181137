import React from 'react';

import { Dwv } from './Component/Dwv';
// import { DwvSlider } from './Component/DwvSlider';
import { Dwn } from './Component/Dwn';
import { New } from './Component/New';
import { Try1 } from './Component/Try1';


function App() {
 return(
 <>
 {/* <Try1/> */}
     {/* <Dwv/> */}
  <New/>
 </>
 )
}

export default App;
